const setContext = (context) => {
  return { type: "SET_CONTEXT", payload: context };
};
const setProgress = (progress) => {
  return { type: "SET_PROGRESS", payload: progress };
};
const setNextPossibleStage = (stage) => {
  return { type: "SET_NEXT_POSSIBLE_STAGE", payload: stage };
};
const setClickedStageThreeTab = (clicked) => {
  return { type: "SET_CLICKED_STAGE_THREE_TAB", payload: clicked };
};
const setStageOneLiner = (line) => {
  return { type: "SET_STAGE_ONE_LINER", payload: line };
};
const setCourseOutlineId = (id) => {
  return { type: "SET_COURSE_OUTLINE_ID", payload: id };
};

const setPreferredCourseId = (courseId) => {
  return { type: "SET_PREFERRED_COURSE_ID", payload: courseId };
};

const setIsReschedule = (bool) => {
  return { type: "SET_IS_RESCHEDULE", payload: bool };
};

const setFetchReferrerDetails = (bool) => {
  return { type: "SET_FETCH_REFERRER_DETAILS", payload: bool };
};

const setCurrentStage = (stage) => {
  return { type: "SET_CURRENT_STAGE", payload: stage };
};

const setStageOneDetails = (details) => {
  return { type: "SET_STAGE_ONE_DETAILS", payload: details };
};
const setStageTwoDetails = (details) => {
  return { type: "SET_STAGE_TWO_DETAILS", payload: details };
};
const setStageThreeDetails = (details) => {
  return { type: "SET_STAGE_THREE_DETAILS", payload: details };
};
const setStageFourDetails = (details) => {
  return { type: "SET_STAGE_FOUR_DETAILS", payload: details };
};
const setPersonalisationDetails = (details) => {
  return { type: "SET_PERSONALISATION_DETAILS", payload: details };
};

const setVariantNumber = (variantNumber) => {
  return { type: "SET_VARINANT_NUMBER", payload: variantNumber };
};
const setStudentsInBookedBatch = (data) => {
  return { type: "SET_STUDENTS_IN_BOOKED_BATCH", payload: data };
};
const resetBookingDetails = () => {
  return {
    type: "RESET_DETAILS_AFTER_BOOKING",
  };
};

const setBookingCompletedStatus = (status) => {
  return {
    type: "SET_IS_BOOKING_COMPLETED",
    payload: status,
  };
};
const setCoursesUnbooked = (data) => {
  return { type: "SET_COURSES_UNBOOKED", payload: data };
};
export {
  setContext,
  setProgress,
  setCurrentStage,
  setStageOneLiner,
  setStageOneDetails,
  setStageTwoDetails,
  setStageThreeDetails,
  setStageFourDetails,
  setNextPossibleStage,
  setClickedStageThreeTab,
  setCourseOutlineId,
  setPersonalisationDetails,
  setVariantNumber,
  setIsReschedule,
  setPreferredCourseId,
  setFetchReferrerDetails,
  setStudentsInBookedBatch,
  resetBookingDetails,
  setBookingCompletedStatus,
  setCoursesUnbooked,
};
