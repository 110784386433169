import axios from "axios";

const setNextClassDetails = (data) => {
  return { type: "SET_NEXT_CLASS_DETAILS", payload: data };
};

const setZoomModalState = (data) => {
  return { type: "SET_ZOOM_MODAL_ACTIVE_STATE", payload: data };
};

const setZoomModalPercentage = (data) => {
  return { type: "SET_ZOOM_MODAL_ACTIVE_STATE_PERCENTAGE", payload: data };
};

const setTimeLeft = (data) => {
  return { type: "SET_TIME_LEFT", payload: data };
};

const setSnackbarState = (data) => {
  return { type: "SET_SNACKBAR_STATE", payload: data };
};

const setIpdata = (data) => {
  return { type: "SET_IP_ADDRESS", payload: data };
};

export {
  setNextClassDetails,
  setZoomModalState,
  setZoomModalPercentage,
  setTimeLeft,
  setSnackbarState,
  setIpdata,
};
