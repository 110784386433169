const setUpcomingClasses = (profileObject) => {
  return { type: "SET_UPCOMING_CLASSES", payload: profileObject };
};
const setPendingAssignments = (assignmentObject) => {
  return { type: "SET_PENDING_ASSIGNMENTS", payload: assignmentObject };
};
const setEnrolledCourses = (enrolledCoursesData) => {
  return { type: "SET_ENROLLED_COURSES", payload: enrolledCoursesData };
};
const setScrollToAssignmentId = (assignmentId) => {
  return { type: "SET_SCROLL_TO_ASSIGNMENT_ID", payload: assignmentId };
};
const setStudentProfileCharacteristics = (profilecharactersitics) => {
  return {
    type: "SET_STUDENT_PROFILE_CHARACTERISTICS",
    payload: profilecharactersitics,
  };
};

const setSessionStatus = (status) => {
  return {
    type: "SET_NEW_SESSION",
    payload: status,
  };
};

const setStudioNotification = (studioNotificationValue) => {
  return { type: "SET_STUDIO_NOTIFICATION", payload: studioNotificationValue };
};
const setHomeWorkViewData = (homeWorkViewData) => {
  return { type: "SET_HOMEWORK_VIEW_DATA", payload: homeWorkViewData };
};
const setCurrentProfile = (profileObject) => {
  return { type: "SET_CURRENT_PROFILE", payload: profileObject };
};
const setSidebarActive = (sidebarStatus) => {
  return { type: "SET_SIDEBAR_ACTIVE", payload: sidebarStatus };
};
const setProfileSelectionPage = (profileStatus) => {
  return { type: "SET_PROFILE_SELECTION_PAGE", payload: profileStatus };
};
const setCalendarScrollEvent = (calendarScroll) => {
  return { type: "SET_CALENDAR_SCROLL_EVENT", payload: calendarScroll };
};
const setProfileCompletionProgress = (completionProgress) => {
  return {
    type: "SET_PROFILE_COMPLETION_PROGRESS",
    payload: completionProgress,
  };
};

const setBatchAllotmentModal = (batchAllotmentModalObject) => {
  return {
    type: "SET_BATCH_ALLOTMENT_MODAL",
    payload: batchAllotmentModalObject,
  };
};

const setGameJSon = (json) => {
  return {
    type: "SET_GAME_JSON",
    payload: json,
  };
};

const setGameStatus = (status) => {
  return {
    type: "SET_GAME_STATUS",
    payload: status,
  };
};

const setCurrentQuestion = (status) => {
  return {
    type: "SET_CURRENT_QUESTION",
    payload: status,
  };
};

const setTotalCoins = (coins) => {
  return {
    type: "SET_TOTAL_COINS",
    payload: coins,
  };
};

const setCurrentGameTime = (seconds) => {
  return {
    type: "SET_CURRENT_GAME_TIME",
    payload: seconds,
  };
};

const setGameCoins = (coins) => {
  return {
    type: "SET_GAME_COINS",
    payload: coins,
  };
};
//

const setCurrentProfileStatus = (data) => {
  return {
    type: "SET_CURRENT_PROFILE_STATUS",
    payload: data,
  };
};

const setGameAttemptId = (id) => {
  return {
    type: "SET_GAME_ATTEMPT_ID",
    payload: id,
  };
};

const setTotalTimeElapsed = (time) => {
  return {
    type: "SET_TOTAL_TIME_ELAPSED",
    payload: time,
  };
};

const setDashboardAllProfilesClassesDetails = (allClassesData) => {
  return {
    type: "SET_DASHBOARD_ALL_PROFILES_CLASS_DETAILS",
    payload: allClassesData,
  };
};
const setDAshboardALlCompletedClassesDetails = (completedClasses) => {
  return {
    type: "SET_DASHBOARD_ALL_COMPLETED_CLASS",
    payload: completedClasses,
  };
};
const setDashboardAlertStatus = (alertStatus) => {
  return {
    type: "SET_DASHBOARD_ALERT_STATUS",
    payload: alertStatus,
  };
};
const setDashboardAlertData = (alertData) => {
  return {
    type: "SET_DASHBOARD_ALERT_DATA",
    payload: alertData,
  };
};

const setDashboardEntryPoint = (entryPoint) => {
  return { type: "SET_DASHBOARD_ENTRY_POINT", payload: entryPoint };
};
const setDashboardZoomModalsActive = (active) => {
  return { type: "SET_DASHBOARD_ZOOM_MODALS_ACTIVE", payload: active };
};
const setAllClassesRetreivalStatus = (status) => {
  return { type: "SET_ALL_CLASSES_RETREIVAL_STATUS", payload: status };
};
const setDashboardAllClassesApiLastInvoked = (time) => {
  return { type: "SET_DASHBOARD_ALL_CLASSES_API_LAST_INVOKED", payload: time };
};
const setNextEduUser = (nextEduUser) => {
  return {
    type: "SET_NEXT_EDU_USER",
    payload: nextEduUser,
  };
};

const setCallBackRequested = (requested) => {
  return {
    type: "SET_CALLBACK_REQUESTED",
    payload: requested,
  };
};

const setShowCallBackRequested = (show) => {
  return {
    type: "SET_SHOW_CALLBACK_REQUESTED",
    payload: show,
  };
};

const setQualificationModal = (show) => {
  return {
    type: "SET_SHOW_QUALIFICATION_MODAL",
    payload: show,
  };
};

export {
  setUpcomingClasses,
  setPendingAssignments,
  setEnrolledCourses,
  setScrollToAssignmentId,
  setStudentProfileCharacteristics,
  setStudioNotification,
  setHomeWorkViewData,
  setCurrentProfile,
  setSidebarActive,
  setCalendarScrollEvent,
  setProfileCompletionProgress,
  setProfileSelectionPage,
  setBatchAllotmentModal,
  setGameJSon,
  setGameAttemptId,
  setDashboardAllProfilesClassesDetails,
  setDashboardAlertStatus,
  setDashboardAlertData,
  setDashboardEntryPoint,
  setDashboardZoomModalsActive,
  setAllClassesRetreivalStatus,
  setCurrentProfileStatus,
  setGameStatus,
  setCurrentQuestion,
  setCurrentGameTime,
  setTotalTimeElapsed,
  setTotalCoins,
  setGameCoins,
  setDashboardAllClassesApiLastInvoked,
  setSessionStatus,
  setNextEduUser,
  setDAshboardALlCompletedClassesDetails,
  setCallBackRequested,
  setShowCallBackRequested,
  setQualificationModal,
};
